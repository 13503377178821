<template>
  <div class="container">
    <content-title :nav="nav"></content-title>

    <div class="contentList">
      <div style="display:flex; justify-content: space-between;">
        <h4 class="sec-title" >广告位信息列表</h4>
        <div class="PERInformation-from">
            <el-button @click="search()" type="primary">新增</el-button>
        </div>
      </div>
      <div class="paging">
        <el-table :header-cell-style="{ background: '#EAEAEA', color: '#434343' }" :data="recording"
          tooltip-effect="dark" style="width: 100%; margin-top:20px;" highlight-current-row
          v-loading="is_loading">
          <el-table-column prop="id" align="center" label="id"></el-table-column>
          <el-table-column align="center" label="Banner" width="240px">
              <template slot-scope="{ row }">
                  <div>
                    <el-image 
                        :src="row.pic"
                        class="table-img"
                        :preview-src-list="[ row.pic ]">
                    </el-image>
                  </div>
              </template>
          </el-table-column>
          <el-table-column prop="activity_id" align="center" label="活动id"></el-table-column>
          <el-table-column prop="banner_name" align="center" label="Banner名称"></el-table-column>
          <el-table-column prop="create_time" align="center" label="购买时间"></el-table-column>
          <el-table-column prop="end_time" align="center" label="截止时间"></el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.state == 1" style="color: #23c3c4">正在使用</span>
              <span v-else-if="row.state == 2" style="color: #b8b8b8">未使用</span>
            </template>
          </el-table-column>
          <el-table-column prop="is_recommend" align="left" label="操作" width="300">
            <template slot-scope="{row}">
              <div style="display:flex;flex-wrap:wrap">
                <el-button v-if="row.state == 2" @click="toDetails(row)" size="small" type="success">上架</el-button>
                <el-button v-if="row.state == 1" @click="toDetails(row)" size="small" type="success">下架</el-button>
                <el-button @click="toDetails(row)" size="small" type="success">编辑</el-button>
              </div>

            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-pagination background  @current-change="handleCurrentChange"
          :current-page.sync="currentPage" :page-size="10" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import newMec from '../../components/newMec.vue'
export default {
  components: { newMec },
  data() {
    return {
      nav: {
        firstNav: '运营管理中心',
        secondNav: '广告位管理',
      },
      is_loading: false,
      recording: [], 
      pageSize: 10,
      currentPage: 1,
      total: 0,
    }
  },
  created(){
    this.GetList()
  },
  methods: {
    addVisible(event) {
      if (event == false) {
        if (this.leimuF != '') {
          this.disableds = false
        } else {
          this.disableds = true
          this.leimuZ = ''
        }
      }
    },
    search() {
      this.GetList()
    }, //查询
    GetList() {
      this.is_loading = true
      let url = 'user/exhibitionPic/queryManagerListPage'
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.recording = res.data.data.rows
          this.total = res.data.data.total
          this.is_loading = false
        })
        .catch((rej) => {
          this.is_loading = false
        })
    },
    handleCurrentChange(){
      this.GetList()
    },
    handleClose(done) {
      this.$confirm('确认关闭?')
        .then((_) => {
          this.user_id = null
          this.bindVisible = false
          this.bindMecVisible = false
          done()
        })
        .catch((_) => {})
    },
  },
}
</script>
<style >
.PERInformation-from > *:not(:last-child) {
  margin-right: 1rem;
}
.table-img{
    width: 200px;
    height: 100px;
}
</style>
